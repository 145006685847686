import { createSelector } from 'reselect';
import { isFailedSelector } from 'state-domains/utils';

import { ShimState } from '../../../types';
import { AuthenticationState } from '../types';

const authentication = (state: Partial<ShimState>): AuthenticationState => {
    const { authentication = {} as AuthenticationState } = state || {};
    return authentication;
};

const getRenewTimeout = createSelector(
    authentication,
    ({ renewTimeout = 0 }: AuthenticationState) => renewTimeout,
);

const getErrorStatus = createSelector(authentication, ({ error = {} }: AuthenticationState) => {
    if (error) {
        const { status = 0 } = error;
        return status;
    }
    return 0;
});

const getLogoutState = createSelector(
    authentication,
    ({ loggedOutState: logState }: AuthenticationState) => logState,
);

const getLogoutRedirectUrl = createSelector(
    authentication,
    ({ logoutRedirectUrl: url }: AuthenticationState) => url,
);

export const selectors = {
    authentication,
    getRenewTimeout,
    getErrorStatus,
    getLogoutState,
    getLogoutRedirectUrl,
    isAuthStateFailed: isFailedSelector(authentication),
};
